import React from "react";

const WorkInProgress = () => {
  return (
    <>
      <div className="bg-white rounded-b-2xl">
        <div className=" top-4 left-4 w-32 p-2 ">
          <img src="https://api.qiks.ro/assets/QiksLogo.png" alt="Logo" />
        </div>
      </div>
      <div className="relative flex flex-col items-center justify-center h-screen bg-[#222831] text-white">
        {/* Text in the center */}
        <div className="text-center z-10 mb-12">
          <h1 className="text-3xl font-bold">Working on it </h1>
          <h4 className="text-sm mt-2">Try again later!</h4>
          <h4 className="text-sm mt-2 ">@Qiks</h4>
        </div>

        {/* Bouncing balls */}
        <div className="flex justify-center space-x-2 mt-4">
          <div className="w-4 h-4 bg-white rounded-full bounce delay-0"></div>
          <div className="w-4 h-4 bg-white rounded-full bounce delay-1"></div>
          <div className="w-4 h-4 bg-white rounded-full bounce delay-2"></div>
        </div>
      </div>
    </>
  );
};

export default WorkInProgress;
